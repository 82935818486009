export function NoEventsFound() {
  {
    /* TODO: Edit this text */
  }
  return (
    <p>
      Geen evenementen gevonden - kijk op{" "}
      <a href="https://www.visitzandvoort.nl/uitagenda/" target="_blank">
        Visit Zandvoort
      </a>
    </p>
  );
}
