import React from "react";
import { Route, Routes } from "react-router-dom";
import { Widget } from "./components/Widget";
import translationService from "@feed-factory/feed-factory.translation";
import { WidgetEntityType } from "./models/WidgetEntityType";
import { useQueryParams } from "./helpers/useQueryParams";
import { DMO, dmoAsArray } from "./models/DMO";
import { widgetConfig } from "./config/widgetConfig";
import { getRGBColor } from "./helpers/colorHelper";
import { feedFactoryAxios } from "@feed-factory/feed-factory.helpers";

function App() {
  const queryParams = useQueryParams();
  // Check if correct parameter passed
  const parseWidgetEntityType = (param: string): WidgetEntityType => {
    if (!param) {
      return "all";
    }
    const entityTypes = ["all", "event", "tip"];
    if (typeof param === "string" && entityTypes.includes(param)) {
      return param as WidgetEntityType; // type assertion satisfies compiler
    } else {
      alert("Please use a valid widget type: " + entityTypes.join(", "));
      return "all";
    }
  };

  const parseWidgetDmo = (param: string): DMO => {
    const myDefault = "zandvoort";
    if (!param) {
      return myDefault;
    }
    const entityTypes = dmoAsArray;
    if (typeof param === "string" && entityTypes.includes(param)) {
      return param as DMO; // type assertion satisfies compiler
    } else {
      alert("Please use a valid id: " + entityTypes.join(", "));
      return myDefault;
    }
  };

  const parseWidgetLanguage = (param: string): string => {
    if (!param) {
      return "nl";
    }

    const languages = ["nl", "en", "de"]; // , "de", "fr", "it", "es", "pt", "ja", "zh"
    if (typeof param === "string" && languages.includes(param)) {
      return param;
    } else {
      alert("Please use a valid language: " + languages.join(", "));
      return "nl";
    }
  };

  const widgetEntityType = parseWidgetEntityType(queryParams.get("type"));
  const widgetDmo = parseWidgetDmo(queryParams.get("id"));
  const widgetLanguage = parseWidgetLanguage(queryParams.get("lang"));

  // Load correct fetch endpoint
  if (widgetDmo === "haarlem") {
    feedFactoryAxios.defaults.headers.common[
      "Authorization"
    ] = `Bearer 45304ce4-b990-4f49-b7e1-e3da595debea`;
    feedFactoryAxios.defaults.baseURL = "https://app.thefeedfactory.nl/api";
  }

  // Load translations
  widgetConfig[widgetDmo].translations.forEach((t) => {
    translationService.addResourceBundle(t.lang, widgetDmo, t.file);
  });
  translationService.changeLanguage(widgetLanguage);

  // Set theme as css variable
  const theme = widgetConfig[widgetDmo].theme;
  document.documentElement.style.setProperty(
    "--color-primary-rgb",
    getRGBColor(theme.primaryColor)
  );
  document.documentElement.style.setProperty(
    "--color-secondary-rgb",
    getRGBColor(theme.secondaryColor)
  );

  if (widgetEntityType)
    return (
      <Routes>
        <Route
          path="/"
          element={
            <Widget
              filterOnType={widgetEntityType}
              dmo={widgetDmo}
              lang={widgetLanguage}
            />
          }
        />
      </Routes>
    );
}

export default App;
