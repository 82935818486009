import visitZandvoortLogoImg from "../assets/visit-zandvoort-logo.png";
import visitHaarlemLogoImg from "../assets/visit-haarlem-logo.svg";
import haarlemNL from "../locales/nl/haarlem.json";
import zandvoortNL from "../locales/nl/zandvoort.json";
import haarlemEN from "../locales/en/haarlem.json";
import zandvoortEN from "../locales/en/zandvoort.json";
import haarlemDE from "../locales/de/haarlem.json";
import zandvoortDE from "../locales/de/zandvoort.json";

const MAX_ITEM_COUNT = 10;

export interface WidgetTypeInfo {
  keywords: string;
}
export interface WidgetTypes {
  [type: string]: WidgetTypeInfo;
}

interface WidgetConfig {
  [dmoId: string]: {
    logoUrl: string;
    baseParams: {
      format: string;
      size: number;
      sortField: string;
      sortOrder: string;
      userorganization: string;
      wfstatus: string;
      keywords?: string;
    };
    types: WidgetTypes;
    translations: { lang: string; file: any }[];
    theme: {
      primaryColor: string;
      secondaryColor: string;
    };
  };
}

export const widgetConfig: WidgetConfig = {
  zandvoort: {
    logoUrl: visitZandvoortLogoImg,
    baseParams: {
      format: "json",
      size: MAX_ITEM_COUNT,
      sortField: "eventStart",
      sortOrder: "ASC",
      userorganization: "Visit Zandvoort",
      wfstatus: "approved",
    },
    types: {
      tip: {
        keywords: "tipzandvoort",
      },
      event: {
        keywords: "uitagenda",
      },
    },
    translations: [
      { lang: "nl", file: zandvoortNL },
      { lang: "en", file: zandvoortEN },
      { lang: "de", file: zandvoortDE },
    ],
    theme: {
      primaryColor: "#021353",
      secondaryColor: "#1270C5",
    },
  },
  haarlem: {
    logoUrl: visitHaarlemLogoImg,
    baseParams: {
      format: "json",
      size: MAX_ITEM_COUNT,
      sortField: "eventStart",
      sortOrder: "ASC",
      userorganization: "Haarlem Marketing",
      wfstatus: "approved",
    },
    types: {},
    translations: [
      { lang: "nl", file: haarlemNL },
      { lang: "en", file: haarlemEN },
      { lang: "de", file: haarlemDE },
    ],
    theme: {
      primaryColor: "#AA8341",
      secondaryColor: "#000000",
    },
  },
};
