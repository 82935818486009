import { DMO } from "../models/DMO";
import { widgetConfig, WidgetTypeInfo } from "../config/widgetConfig";

export const getDmoTypeInfo = (
  dmo: DMO,
  type: string
): WidgetTypeInfo | null => {
  const myConfig = widgetConfig[dmo];
  if (myConfig.types) {
    // Check if type is available
    // @ts-ignore
    const myType = myConfig.types[type];
    if (!myType) {
      return null;
    } else {
      return {
        keywords: myType.keywords,
      };
    }
  }
};
