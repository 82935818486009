import { feedFactoryAxios } from "@feed-factory/feed-factory.helpers";
import { ExternalEventItemModel } from "@feed-factory/feed-factory.models.ndtrc/dist/Ndtrc";
import { EventModel } from "../models/EventModel";
import { UrlDetails } from "@feed-factory/feed-factory.models.ndtrc";
import { WidgetEntityType } from "../models/WidgetEntityType";
import { DMO } from "../models/DMO";
import { widgetConfig, WidgetTypeInfo } from "../config/widgetConfig";
import { getDmoTypeInfo } from "./getDmoTypeInfo";

const getTitleInLang = (
  event: ExternalEventItemModel,
  lang: string
): string => {
  console.log(event.trcItemDetails);
  const langItem = event.trcItemDetails.find((item) => item.lang === lang);
  if (langItem) return langItem.title;
  // If specified language not found, return primary language of item
  return event.trcItemDetails[0].title;
};

export const retrieveEvents = async (
  dmo: DMO,
  filterOnType: WidgetEntityType = "all",
  t: any, // translation function
  lang: string = "nl"
): Promise<EventModel[]> => {
  const baseQueryParams = widgetConfig[dmo].baseParams;

  // Get additional filter params based on type
  const myTypeInfo: WidgetTypeInfo = getDmoTypeInfo(dmo, filterOnType);
  if (myTypeInfo?.keywords) {
    baseQueryParams.keywords = myTypeInfo?.keywords;
  }

  const events = await feedFactoryAxios.get("events", {
    params: baseQueryParams,
  });

  if (!events?.data?.results) {
    return Promise.reject("Could not retrieve events data...");
  }

  const parsedEvents: EventModel[] = parseEvents(
    events.data.results,
    filterOnType,
    t,
    lang
  );
  return Promise.resolve(parsedEvents);
};

const parseEvents = (
  events: ExternalEventItemModel[],
  filterOnType: WidgetEntityType,
  t: any, // translation function
  lang: string
): EventModel[] => {
  let filteredEvents = events.filter((event) => {
    const hasDates =
      event?.calendar?.singleDates?.length > 0 ||
      event?.calendar?.patternDates?.length > 0;
    const hasImage = event.files && event.files.length > 0;
    const hasTitle = event.trcItemDetails && event.trcItemDetails.length > 0;
    const isTip = event?.keywords && event?.keywords.includes("tipzandvoort");

    switch (filterOnType) {
      case "all":
        return hasTitle && hasImage && (hasDates || isTip);
      case "event":
        return hasTitle && hasImage && hasDates && !isTip;
      case "tip":
        return hasTitle && hasImage && isTip;
      default:
        console.error("Invalid filter type: " + filterOnType);
        return false;
    }
  });

  let parsedEvents = filteredEvents.map((event) => {
    let mainImage = event.files[0];
    let mainImages = event.files.filter((file) => file.main);
    if (mainImages && mainImages.length > 0) {
      mainImage = mainImages[0];
    }
    const title = getTitleInLang(event, lang);

    const isTip = event?.keywords && event?.keywords.includes("tipzandvoort");

    let eventFormattedDate: string = "";
    if (!isTip) {
      let eventRawStartDate: string = null;
      let eventRawEndDate: string = null;

      if (event.calendar?.singleDates.length > 0) {
        // TODO: Use the first *upcoming* date (ignoring dates in the past)
        eventRawStartDate = event.calendar.singleDates[0].date;
      } else if (event.calendar?.patternDates.length > 0) {
        eventRawStartDate = event.calendar.patternDates[0].startdate;
        eventRawEndDate = event.calendar.patternDates[0].enddate;
      } else {
        // ...
      }
      const dateOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      const eventFormattedStartDate: string =
        lang === "nl"
          ? new Date(eventRawStartDate).toLocaleDateString("nl-NL", dateOptions)
          : lang === "en"
          ? new Date(eventRawStartDate).toLocaleDateString("en-GB", dateOptions)
          : new Date(eventRawStartDate).toLocaleDateString(
              "de-DE",
              dateOptions
            );

      eventFormattedDate = eventFormattedStartDate;

      let eventFormattedEndDate: string = null;
      if (eventRawEndDate) {
        eventFormattedEndDate = new Date(eventRawEndDate).toLocaleDateString(
          "nl-NL",
          dateOptions
        );

        eventFormattedDate += " - " + eventFormattedEndDate;
      }
    } else {
      eventFormattedDate = "Tip";
    }

    // Set default urls in case none in item
    let visitUrl = isTip
      ? t("callToAction.url.tips")
      : t("callToAction.url.uitagenda");

    // Fill with language-specific URL
    if (
      event.contactinfo !== undefined &&
      event.contactinfo.urls !== undefined &&
      event.contactinfo.urls.length > 0
    ) {
      let myUrl: UrlDetails = event.contactinfo.urls.find(
        (url) => url.targetLanguage === lang
      );
      if (myUrl) {
        visitUrl = myUrl.url;
      } else {
        visitUrl = event.contactinfo.urls[0].url;
      }
    }

    return {
      id: event.id as string,
      title: title,
      date: eventFormattedDate,
      url: visitUrl + "?utm=zvwidget",
      image: {
        url: mainImage.hlink,
        alt: title,
      },
      isTip: isTip,
    };
  });

  return parsedEvents;
};
