import { Spinner } from "./Spinner";
import { MoreInfo } from "./MoreInfo";
import React, { useEffect, useState } from "react";
import { EventModel } from "../models/EventModel";
import { retrieveEvents } from "../helpers/data";
import { EventsSwiper } from "./Events/EventsSwiper";
import { useTranslation } from "react-i18next";
import { WidgetEntityType } from "../models/WidgetEntityType";
import { FooterData } from "../models/FooterData";
import { DMO } from "../models/DMO";
import { getDmoLogoUrl } from "../helpers/getDmoLogoUrl";

export const Widget = (props: {
  filterOnType: WidgetEntityType;
  dmo: DMO;
  lang: string;
}) => {
  const [events, setEvents] = useState<EventModel[]>([]);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const eventsAreLoaded: boolean = !isLoading && events !== undefined;
  useEffect(() => {
    setIsLoading(true);
    retrieveEvents(props.dmo, props.filterOnType, t, props.lang)
      .then((eventResults) => {
        setEvents(eventResults);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const footerData: FooterData = {
    text:
      props.filterOnType === "tip"
        ? t(`${props.dmo}:callToAction.text.tips`)
        : t(`${props.dmo}:callToAction.text.uitagenda`),
    linkUrl:
      props.filterOnType === "tip"
        ? t(`${props.dmo}:callToAction.url.tips`)
        : t(`${props.dmo}:callToAction.url.uitagenda`),
    logoUrl: getDmoLogoUrl(props.dmo),
  };

  return (
    <div className={"h-screen flex flex-col md:justify-center pt-1 pb-2 px-2"}>
      <h1
        className={
          "font-bold text-sm xs:text-lg md:text-2xl text-theme-primary mb-1"
        }
      >
        {t(`${props.dmo}:title`)}
      </h1>

      {isLoading && <Spinner />}
      {error !== "" && <p className={"text-red-800"}>{error}</p>}

      {/* Side-by-side */}
      <div className={"hidden xxs:grid grid-cols-12 gap-4 h-full"}>
        {eventsAreLoaded && (
          <div className={"col-span-12 md:col-span-12 lg:col-span-12"}>
            <EventsSwiper
              events={events}
              slidesPerView={Math.min(events.length, 3)}
              className={"hidden lg:block"}
            />
            <EventsSwiper
              events={events}
              slidesPerView={Math.min(events.length, 2)}
              className={"hidden md:block lg:hidden"}
            />
            <EventsSwiper
              events={events}
              slidesPerView={Math.min(events.length, 1)}
              className={"md:hidden"}
            />
          </div>
        )}
      </div>

      {/* Row-by-row */}
      <div className={"flex flex-col xxs:hidden h-full"}>
        {eventsAreLoaded && <EventsSwiper events={events} slidesPerView={1} />}
      </div>

      <MoreInfo className={"h-20"} footerData={footerData} />
    </div>
  );
};
