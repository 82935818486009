
import { DMO } from '../models/DMO';
import { widgetConfig } from '../config/widgetConfig';

export const getDmoLogoUrl = (dmo: DMO) => {
  const dmoConfig = widgetConfig[dmo];
  if (!dmoConfig) {
    throw new Error(`No widget config found for DMO ${dmo}`);
  }
  return dmoConfig.logoUrl;
}
