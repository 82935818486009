import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
// import 'swiper/css/effect-cards';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/navigation';
// import 'swiper/css/scrollbar';
import { EventModel } from "../../models/EventModel";
import { EventAsImage } from "./EventAsImage";
import { NoEventsFound } from "./NoEventsFound";
import React from "react";
// import { EffectCards } from "swiper";

export const EventsSwiper = (props: {
  events: EventModel[];
  slidesPerView: number;
  className?: string;
}) => {
  let initialSlide = 1;
  if (props.events.length > 1) {
    initialSlide = 1;
  }
  return (
    <div className={"h-full " + props?.className}>
      {props.events?.length > 0 && (
        <Swiper
          // effect={"coverflow"}
          // grabCursor={true}
          centeredSlides={true}
          modules={[Pagination, Autoplay]} //, EffectCoverflow]} //, Navigation,  Scrollbar, A11y]}
          spaceBetween={25}
          loop={true}
          slidesPerView={props.slidesPerView}
          pagination={{ clickable: true }}
          initialSlide={initialSlide}
          // scrollbar={{draggable: true}}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log('slide change')}
          autoplay={{
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          className={"h-full"}
        >
          {props?.events.map((event) => (
            <SwiperSlide key={event.id}>
              <EventAsImage event={event} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {props.events?.length === 0 && <NoEventsFound />}
    </div>
  );
};
