import { FooterData } from "../models/FooterData";

export const MoreInfo = (props: {
  className?: string;
  footerData: FooterData;
}) => {
  return (
    <div
      className={
        "mt-1 flex h-full items-center content-end justify-center lg:justify-end " +
        props?.className
      }
    >
      <a
        href={props.footerData.linkUrl}
        rel="noreferrer"
        target="_blank"
        className={
          "pr-3.5 sm:text-lg text-link text-theme-secondary hover:text-theme-primary text-right"
        }
      >
        {props.footerData.text}
      </a>
      <a
        href={props.footerData.linkUrl}
        rel="noreferrer"
        target="_blank"
        className={"w-48"}
      >
        <img
          src={props.footerData.logoUrl}
          alt="Visit Zandvoort logo"
          className={""}
        />
      </a>
    </div>
  );
};
