import { EventModel } from "../../models/EventModel";
import "./EventAsImage.scss";

export const EventAsImage = (props: { event: EventModel }) => {
  const event = props.event;

  const titleClasses: string = " text-2xl bottom-8 left-5 pr-5";
  const dateClasses: string = " text-xs sm:text-base";

  return (
    <div className={"relative h-full overflow-hidden event-img-container"}>
      <a href={event.url} target="_blank" rel="noreferrer">
        <img
          src={event.image.url}
          alt={event.image.alt}
          className={
            "absolute top-0 left-0 bottom-0 right-0 w-full h-full object-cover block" +
            " transition-transform ease-in-out duration-500"
          }
        />
        <div
          className={
            "absolute top-0 left-0 bottom-0 right-0 w-full h-full object-cover " +
            "block bg-gradient-to-t via-transparent from-theme-primary opacity-60"
          }
          style={{
            // @ts-ignore
            "--tw-gradient-stops":
              "var(--tw-gradient-from), 30%, transparent, var(--tw-gradient-to, rgb(0 0 0 / 0))",
          }}
        />
        <div
          className={
            "absolute w-full h-full bg-theme-primary top-0 opacity-0 transition-opacity duration-500 event-img-overlay"
          }
        />
        <p className={"absolute text-white " + titleClasses}>{event.title}</p>
        <p
          className={
            "absolute top-0 bg-theme-primary px-2 py-1 text-white" + dateClasses
          }
        >
          {event.date}
        </p>
      </a>
    </div>
  );
};
